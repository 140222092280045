var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{staticClass:"p-0 m-0",attrs:{"sm":"12","md":"3"}},[_c('navlink')],1),_c('b-col',{attrs:{"sm":"12","md":"9"}},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-card',[_c('b-card-body',[_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('div',{staticClass:"demo-spacing-0 mb-2"},[_c('b-alert',{attrs:{"show":"","variant":"primary"}},[_c('div',{staticClass:"alert-body"},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"ml-25"},[_vm._v("Per Page")])],1)])],1),_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{staticClass:"mt-1",attrs:{"sm":"12","md":"6"}},[_c('inputtext',{attrs:{"name":"name","tooltip":_vm.$t('fields.nomineename') +
                      ' ' +
                      _vm.$t('fields.nomineename'),"label":_vm.$t('fields.nomineename'),"placeholder":_vm.$t('fields.enter') + ' ' + _vm.$t('fields.nomineename'),"valuex":_vm.appData.name,"validations":"required"},on:{"updatedata":function (val) { return (_vm.appData.name = val); }}})],1),_c('b-col',{staticClass:"mt-1",attrs:{"sm":"12","md":"6"}},[_c('inputtext',{attrs:{"name":"phonenumber","tooltip":_vm.$t('fields.enter') + ' ' + _vm.$t('fields.phonenumber'),"label":_vm.$t('fields.phonenumber'),"placeholder":_vm.$t('fields.enter') + ' ' + _vm.$t('fields.phonenumber'),"valuex":_vm.appData.phonenumber,"validations":"numeric|length:10"},on:{"updatedata":function (val) { return (_vm.appData.phonenumber = val); }}})],1),_c('b-col',{staticClass:"mt-1",attrs:{"sm":"12","md":"6"}},[_c('inputtext',{attrs:{"name":"email","tooltip":_vm.$t('fields.enter') + ' ' + _vm.$t('fields.email'),"label":_vm.$t('fields.email'),"placeholder":_vm.$t('fields.enter') + ' ' + _vm.$t('fields.email'),"valuex":_vm.appData.email,"validations":"email"},on:{"updatedata":function (val) { return (_vm.appData.email = val); }}})],1),_c('b-col',{staticClass:"mt-1",attrs:{"sm":"12","md":"6"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t("fields.nomineeprefarance")))]),_c('inputvselect',{attrs:{"name":"nomineeprefarance","tooltip":_vm.$t('fields.select') +
                      ' ' +
                      _vm.$t('fields.nomineeprefarance'),"label":function (reldata) { return reldata.name; },"placeholder":_vm.$t('fields.select') +
                      ' ' +
                      _vm.$t('fields.nomineeprefarance'),"valuex":_vm.appData.nomineeprefarance,"keys":"key","options":[
                      {
                        id: 0,
                        name: 'Primary',
                      },
                      {
                        id: 1,
                        name: 'Secondary',
                      } ],"id":"relations","validations":"required"},on:{"updatedata":function (val) { return (_vm.appData.nomineeprefarance = val); }}})],1),_c('b-col',{staticClass:"mt-1",attrs:{"sm":"12"}},[_c('div',{staticClass:"d-flex float-right"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"ArrowLeftIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t("fields.back"))+" ")])],1),(_vm.edittype == 1)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"variant":"primary","to":{
                        name: _vm.GENAPP_APP_STORE_MODULE_NAME + '-view',
                        params: { id: _vm.iddata },
                      }}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"EyeIcon"}}),_vm._v(" "+_vm._s(_vm.$t("fields.view"))+" ")],1):_vm._e(),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"variant":"primary                            ","type":"submit"}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"Edit3Icon"}}),_vm._v(" "+_vm._s(_vm.$t("fields.save"))+" ")],1)],1)])],1)],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }